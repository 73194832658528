import Vue from "vue";
import firebase from "firebase";
import 'tailwindcss/tailwind.css';
import './assets/scss/app.scss';

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID
};

firebase.initializeApp(firebaseConfig);

function register_default() {
  let default_app = import("./modules/default");
  console.log("Registering default app");
  return default_app;
}

function register_bmm() {
  let bmm_app = import("./modules/bmm");
  console.log("Registering BMM app");
  return bmm_app;
}

function register_tenant() {
  // let ins_app = import("./modules/insurer");
  let tenant_app = import("./modules/tenant");
  console.log("Registering Tenant");
  // return ins_app;
  return tenant_app;
}

function register_tenant_customer() {
  // let dist_app = import("./modules/distributor");
  let tenant_customer_app = import("./modules/tenant_customer");
  console.log("Registering Tenant Customer");
  // return dist_app;
  return tenant_customer_app;
}

firebase.auth().onAuthStateChanged(() => {
  console.log("Auth State Changed");
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    // console.log(currentUser);
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then(idTokenResult => {
        var claims = idTokenResult.claims;

        if (claims.isBmmUser) {
          console.log(`Register bmm app ${claims.isBmmUser}`);
          register_bmm();
          // } else if (claims.isInsurer) {
        } else if (claims.isTenantUser) {
          console.log("Register Tenant app");
          console.log(`Register Tenant app ${claims.isTenantUser}`);
          register_tenant();
          // } else if (claims.isDistributor) {
        } else if (claims.isTenantCustomerUser) {
          console.log(`Register TenantCutomer app ${claims.isTenantCustomerUser}`);
          register_tenant_customer();
        } else {
          console.log(claims);
          register_default();
          // firebase.auth().signOut();
          // this.$router.replace({ name: "Login" });
          // new Vue({
          //   router,
          //   store,
          //   vuetify,
          //   render: h => h(App)
          // }).$mount("#app");
        }
      });
  } else {
    console.log("Register the default app");
    register_default();
  }
});
